import React from 'react'
import { useTranslation } from 'react-i18next';

import Rating from '../../../components/rating/Rating'
import PartyDTO, { getCheapestOption } from '../../../types/PartyDTO'
import { CheckBoxInput, NumberInput, TextInput } from '../../../common/Input'
import PartyOptions from '../../../common/PartyOptions'
import { BookingPageProps, bookingWidgetConnector } from '../bookingPageSlice'
import styles from './BookingWidget.module.css'
import DateInput from '../../../common/DateInput'
import { parseDate } from '../../../common/utils'
import { useNavigate } from 'react-router-dom'
import { Moment } from 'moment/moment'
import PartyOptionDTO from '../../../types/PartyOptionDTO'
import { PriceType } from '../../../types/PriceDTO'
import Button, { ButtonModification } from '../../../components/button/Button'
import { useShowModalMessage } from '../../../components/modal/ModalWindowContext'

import {
  validateAddress,
  validateAgreement,
  validateDateOfParty,
  validateEmail,
  validateFullName,
  validateNumberOfKids,
} from '../../../utils/BookingValidationUtils'

type BookingWidgetProps = {
  party: PartyDTO;
}

// const BookingWidget = (state: BookingWidgetStateProps & BookingWidgetProps) => {
const BookingWidget = (state: BookingPageProps & BookingWidgetProps) => {
  const { t } = useTranslation();

  const [totalCost, setTotalCost] = React.useState<number>();
  const navigate = useNavigate();

  const errors = state.errors;
  const showMessage = useShowModalMessage();
  const cheapestOption = getCheapestOption(state.party);

  React.useEffect(() => {
    let total: number = 0;
    const option = state.booking.selectedPartyOption;
    let numberOfKids = state.booking.numberOfKids;

    if (option && option.price && numberOfKids) {
      if (option.priceType === PriceType.VA) {
        total = option.price;
      } else {
        if (option.minKids && numberOfKids < option.minKids) {
          numberOfKids = option.minKids;
        }

        if (option.maxKids && numberOfKids > option.maxKids) {
          numberOfKids = option.maxKids;
        }

        total = option.price * numberOfKids;

        if (option.minAmount && total < option.minAmount) {
          total = option.minAmount;
        }

        if (option.maxAmount && total > option.maxAmount) {
          total = option.maxAmount;
        }
      }

      setTotalCost(+total.toFixed(3));
    }
    // validate();
  }, [state])

  React.useEffect(() => {
    if (state.party.id !== state.selectedParty?.id)
      state.setParty(state.party);
  }, [state])

  const validate = (targetObject?: any): boolean => {
    let result = true;
    const newState = { ...state.errors };
    let isvalid: boolean;

    const myState = { ...state.booking, ...targetObject };

    [newState.dateOfParty, isvalid] = validateDateOfParty(myState.dateOfParty);
    result = result && isvalid;

    const option = myState.selectedPartyOption;
    const minKids = option?.minKids ?? 0;
    const maxKids = option?.maxKids ?? 0;

    [newState.numberOfKids, isvalid] = validateNumberOfKids(
      myState.numberOfKids,
      minKids,
      maxKids
    )
    result = result && isvalid;

    [newState.fullName, isvalid] = validateFullName(myState.fullName);
    result = result && isvalid;
    [newState.agreement, isvalid] = validateAgreement(myState.agreement);
    result = result && isvalid;
    [newState.email, isvalid] = validateEmail(myState.email);
    result = result && isvalid;

    if (!state.party.isVendorsPlace) {
      [newState.eventAddress, isvalid] = validateAddress(myState.eventAddress);
      result = result && isvalid;
    } else {
      newState.eventAddress = undefined;
    }

    state.setErrors(newState);

    return result;
  }

  const handleChange = (newValue: string | number | Moment | boolean, name: string) => {
    if (['email', 'fullName', 'numberOfKids', 'dateOfParty', 'nameChild', 'birthday', 'agreement', 'eventAddress'].indexOf(name) !== -1) {
      state.setErrors({ ...state.errors, [name]: undefined });
    }

    let propertyChange = {}
    // state.setDateOfParty(newValue as Moment);
    if (name === 'dateOfParty') {
      // state.setDateOfParty(newValue as Moment);
      // console.log(name + "  > " + newValue);
    } else if (name === 'birthday') {
      state.setBirthday(newValue as Moment);
    } else {
      propertyChange = { [name]: newValue };
      state.setBooking({ ...state.booking, ...propertyChange });
      if (name !== 'country') {
        validate(propertyChange);
      }
    }
  }

  const submitBooking = async () => {
    if (!validate()) {
      return;
    }

    const result: any = await state.submitBooking(state.booking);
    if (result.error != null) {
      showMessage.error({ message: `${result.error.code}:\n${result.error.message}`, title: t('Request error') });
    } else {
      navigate(`/party/${state.selectedParty?.id}/book/done`);
    }
  }

  const calculateMinKids = (option?: PartyOptionDTO): number => {
    return Math.max(option?.minKids ?? 0, 1);
  }

  const calculateMaxKids = (option?: PartyOptionDTO) => {
    let result = option?.maxKids ?? 0;

    if (result === 0 || result < calculateMinKids(option)) {
      result = 1000;
    }

    return result;
  }

  const updateSelectedOption = (option: PartyOptionDTO) => {
    state.setSelectedOption(option);
    validate({ selectedPartyOption: option });
  }

  if (!state.party) return <></>

  return (
    <form className={styles.bookingWidget}>
      <div className={styles._heading}>
        <p className={styles._price}>
          {cheapestOption ?
            <>
              {t('from €{{price}}', { price: cheapestOption?.price })}
              {cheapestOption?.priceType === 'pp' ? t('p.p.') : ''}
            </>
            :
            <>
              {t('t.b.a.')}
            </>
          }

        </p>
        <div className={styles._rating}>
          <Rating rating={state.party.rating} review={0} tooltipPlace={'top-end'} />
        </div>
      </div>
      <DateInput
        title={t("Date of the party")}
        name="dateOfTheParty"
        value={parseDate(state.booking.dateOfParty)}
        onChange={state.setDateOfParty}
        showTimePicker longDate pickerType="future"
        errorMessage={errors.dateOfParty}
      />
      <NumberInput
        title={t("Number of kids")}
        name="numberOfKids"
        value={state.booking.numberOfKids}
        onChange={handleChange}
        errorMessage={errors.numberOfKids}
        min={calculateMinKids(state.booking.selectedPartyOption)}
        max={calculateMaxKids(state.booking.selectedPartyOption)}
      />
      <TextInput
        title={t("Your name")}
        name="fullName"
        value={state.booking.fullName}
        onChange={handleChange}
        errorMessage={errors.fullName}
      />
      <TextInput
        title={t("Your email")}
        name="email"
        value={state.booking.email}
        onChange={handleChange}
        errorMessage={errors.email}
        lastField={state.party.isVendorsPlace}
      />
      {!state.party.isVendorsPlace && (
        <TextInput
          title={t("Where should we go?")}
          name="eventAddress"
          value={state.booking.eventAddress}
          onChange={handleChange}
          errorMessage={errors.eventAddress}
          lastField
        />
      )}
      <PartyOptions
        title={t('Options')}
        partyOptions={state.party.options}
        selectedOption={state.booking.selectedPartyOption}
        setSelectedOption={updateSelectedOption}
      />
      <CheckBoxInput
        title={t("I agree with KidsVenu processing my personal data")}
        name="agreement"
        value={state.booking.agreement}
        onChange={handleChange}
        errorMessage={errors.agreement ? t("Field is required") : undefined}
      />
      <CheckBoxInput
        title={t('Country')}
        name="country"
        value={state.booking.country}
        onChange={handleChange}
      />
      <div className={styles._actions}>
        <Button
          modification={[ButtonModification.filled, ButtonModification.fluid]}
          type={'submit'}
          text={t('Request')}
          action={async (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            await submitBooking();
          }}
        />
      </div>
      <div className={styles._check}>
        <p>
          {(state.booking.selectedPartyOption?.price && state.booking.numberOfKids) ?
            <>
              <span>
                {state.booking.selectedPartyOption?.priceType === PriceType.PP ?
                  t('€{{price}} x {{numberOfKids}} kids', { price: state.booking.selectedPartyOption?.price, numberOfKids: state.booking.numberOfKids })
                  :
                  t('€{{price}} for all', { price: state.booking.selectedPartyOption?.price })
                }
              </span>
              <span>€ {totalCost}</span>
            </>
            :
            <></>
          }

        </p>
        {/*<p>*/}
        {/*    <span>KidsVenu fee</span>*/}
        {/*    <span>€ 0</span>*/}
        {/*</p>*/}
      </div>
      <div className={styles._check + ' ' + styles._total}>
        <p>
          <span>{t('Total')}</span>
          <span>
          {totalCost ? '€' +totalCost : t('t.b.a.')}

          </span>
        </p>
      </div>
    </form>
  );
}

export default bookingWidgetConnector(BookingWidget);